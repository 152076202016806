<template>
    <v-layout>
        <!-- o recibo está sendo gerado no SSR -->
    </v-layout>
</template>

<script type="plain/text">
import DocService from "@/scripts/services/doc.service";
export default {
    components: {
        DocService
    },
    name: "ReceiptList",
    data() {
        return {
            docService: new DocService()
        }
    },
    created() {
        debugger
        let chave = this.$route.query.chave;
        this.docService.getReceipt(chave, this.callbackReceipt);
    },
    methods: {
        callbackReceipt(response) {
            let filename = 'arquivo.pdf';
            const header = response.headers['content-disposition'];
            const match = header.match(/filename\*=UTF-8''([^;\n]+)/i);

            if (match && match[1]) {
                filename = decodeURIComponent(match[1]);
            }

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
}
</script>

<style scoped></style>